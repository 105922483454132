<template>
  <el-container id="baseId">
    <el-header height="80px">
      <Header :typeFu="1"></Header>
    </el-header>
    <el-container>
      <router-view :key="$route.name" style="flex: 1;"></router-view>
      <Footer></Footer>
    </el-container>
    <div class="to-top"
         v-if="show"
         @click="toTop"
         :style="style">
      <i class="el-icon-top"></i>
    </div>
  </el-container>
</template>
<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    name: "index-index",
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        show: false,
        style: {
          position: 'fixed',
          bottom: "10px"
        },
      };
    },
    created() {
    },
    mounted() {
      window.addEventListener('scroll', this.handleScrollY, true);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScrollY, true);
    },
    methods: {
      toTop() {
        document.body.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      },
      handleScrollY() {
        // 底部距离顶部的高度
        let footerOffsetTop = document.querySelector('.footer').offsetTop;
        // 底部的高度
        let footerOffsetHeight = document.querySelector('.footer').offsetHeight;
        // body的高度
        let bodyOffsetHeight = document.body.offsetHeight;
        // 页面滚动高度
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 0) {
          this.show = true;
        } else {
          this.show = false;
        }
        if (scrollTop < (footerOffsetTop - bodyOffsetHeight + 60)) {
          this.style = {
            position: 'fixed',
            bottom: "10px"
          };
        } else {
          this.style = {
            position: 'absolute',
            bottom: (footerOffsetHeight + 10) + 'px'
          }
        }
      },
    },
  };
</script>
<style lang="scss">
  .flex {
    display: flex;
  }

  body {
    background-color: #f8f8f8;
  }

  .el-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    min-width: 1150px;
    background-color: #f8f8f8;

    .el-header {
      position: fixed;
      width: 100%;
      z-index: 10000;
      padding: 0;
    }

    .el-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin-top: 80px;
    }

    .to-top {
      right: 10px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 30px;
      border-radius: 8px;
      color: #ffffff;
      background-color: #919191;
      cursor: pointer;
      &:hover{
        background-color: #999999;
      }
    }
  }
</style>
